<script setup>
import { onMounted } from 'vue'
import Chart from 'chart.js/auto'

onMounted(() => {
  const choresPerMonthCtx = document.getElementById('choresPerMonth').getContext('2d')
  const choresPerAssigneeCtx = document.getElementById('choresPerAssignee').getContext('2d')
  const choresPerRoomCtx = document.getElementById('choresPerRoom').getContext('2d')

  new Chart(choresPerMonthCtx, {
    type: 'bar',
    data: {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      datasets: [{
        label: 'Chores',
        data: [12, 19, 3, 5, 2, 3, 9, 10, 15, 7, 8, 6],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      }]
    },
    options: {
      scales: {
        y: {
          beginAtZero: true
        }
      }
    }
  })

  new Chart(choresPerAssigneeCtx, {
    type: 'pie',
    data: {
      labels: ['Alice', 'Bob', 'Charlie', 'David'],
      datasets: [{
        label: 'Chores',
        data: [10, 15, 5, 20],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)'
        ],
        borderWidth: 1
      }]
    }
  })

  new Chart(choresPerRoomCtx, {
    type: 'doughnut',
    data: {
      labels: ['Kitchen', 'Living Room', 'Bathroom', 'Bedroom'],
      datasets: [{
        label: 'Chores',
        data: [8, 12, 6, 14],
        backgroundColor: [
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
          'rgba(201, 203, 207, 0.2)'
        ],
        borderColor: [
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(255, 205, 86, 1)',
          'rgba(201, 203, 207, 1)'
        ],
        borderWidth: 1
      }]
    }
  })
})
</script>

<template>
  <div class="statistics">
    <h1 class="title">Chore Statistics</h1>
    <p style="color: red">NOTE: this page is work in progress, the data used in these graphs is not valid.</p>
    <div class="columns">
      <div class="column is-half">
        <h2 class="subtitle">Chores per Month</h2>
        <canvas id="choresPerMonth"></canvas>
      </div>
      <div class="column is-half">
        <h2 class="subtitle">Chores per Assignee</h2>
        <canvas id="choresPerAssignee"></canvas>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half">
        <h2 class="subtitle">Chores per Room</h2>
        <canvas id="choresPerRoom"></canvas>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>