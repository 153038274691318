<script setup>
const assignees = [
  {
    id: 1,
    first_name: 'John',
    last_name: 'Doe',
    total_points: 120,
    points_this_month: 30,
    badges: ['Gold', 'Silver']
  },
  {
    id: 2,
    first_name: 'Jane',
    last_name: 'Smith',
    total_points: 150,
    points_this_month: 50,
    badges: ['Platinum', 'Gold']
  }
];
</script>

<template>
  <p style="color: red">NOTE: this page is work in progress, data listed here is not the actual data.</p>
  <div class="assignees-list">
    <div v-for="assignee in assignees" :key="assignee.id" class="assignee-card">
      <h2>{{ assignee.first_name }} {{ assignee.last_name }}</h2>
      <p><strong>Total Points:</strong> {{ assignee.total_points }}</p>
      <p><strong>Points This Month:</strong> {{ assignee.points_this_month }}</p>
      <p><strong>Badges:</strong> {{ assignee.badges.join(', ') }}</p>
    </div>
  </div>
</template>

<style scoped>

</style>