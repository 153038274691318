<template>
  <div class="page-product">
    <div class="columns is-multiline">
      <div class="column is-9">
        <figure class="image mb-6">
          <img v-bind:src="chore.get_image">
        </figure>

        <h1 class="title">{{ chore.name }}</h1>

        <p>{{ chore.description }}</p>
      </div>

      <div class="column is-3">
        <h2 class="subtitle">Information</h2>

        <p><strong>Room: </strong>{{ chore.room_name }}</p>
        <p><strong>Score: </strong>{{ chore.score }}</p>
        <p><strong>Urgency: </strong>{{ Math.round(chore.status * 100) }}%</p>

        <AssigneeDropdownMenu class="mt-6" v-bind:assignees="assignees"/>

        <h2 class="subtitle mt-6">History</h2>
        <p v-for="entry in chore.history">{{entry.assignee}} did {{ chore.name }} at {{ formatDate(entry.done_at) }} for {{ entry.difficulty_score }} points.</p>

      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios'
import AssigneeDropdownMenu from "@/components/AssigneeDropdownMenu.vue";

export default {
  name: 'Chore',
  components: {AssigneeDropdownMenu},
  data() {
    return {
      chore: {},
      quantity: 1,
      assignees: {},
    }
  },
  mounted() {
    this.getChore()
    this.getAssignees()
  },
  methods: {
    getChore() {
      const room_slug = this.$route.params.room_slug
      const chore_slug = this.$route.params.chore_slug

      axios
          .get(`/api/v1/chores/${room_slug}/${chore_slug}`)
          .then(response => {
            this.chore = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },
    getAssignees() {
      axios.get('api/v1/assignees')
          .then(response => {
            this.assignees = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
    }
  }
}
</script>

