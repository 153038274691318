<template>
  <div class="column is-3">
    <div class="box">
      <!--          <figure class="image mb-4">-->
      <!--            <img :src="chore.get_thumbnail">-->
      <!--          </figure>-->

      <h3 class="is-size-4">{{ chore.name }}</h3>
      <p class="is-size-6 has-text-grey mt-2">Difficulty score: <strong>{{ chore.score }}</strong></p>
      <p class="is-size-6 has-text-grey mt-2">Status: <strong :class="getStatusClass(chore.status)">{{ Math.round(chore.status * 100) }}%</strong></p>
      <p class="is-size-6 has-text-grey mt-2">You will <strong>earn
        {{ Math.round(chore.score * (1 - chore.status) * 100) / 100 }}</strong> points by resolving this chore!</p>
      <br>
      <progress
          class="progress mb-4 mt-4"
          :class="getProgressClass(chore.status)"
          :value="Math.round(chore.status * 100)"
          max="100"
          style="margin-right: 10px;">{{ Math.round(chore.status * 100) }}%
      </progress>
      <br>

      <div class="buttons mt-4">
        <router-link v-bind:to="chore.get_absolute_url" class="button is-dark mt-4">View Details</router-link>
        <button class="button is-success mt-4" @click="showModal = true">Do Chore</button>
        <!--              <button class="button is-success">Resolve Task</button>-->
        <!--              <button class="button">View Details</button>-->
      </div>
    </div>
  </div>

  <ChoreBoxModal :chore="chore" :assignees="assignees" :showModal="showModal" @close="showModal = false"/>


</template>

<script>
import ChoreBoxModal from "@/components/ChoreBoxModal";
import axios from "axios";

export default {
  name: 'ChoreBox',
  data() {
    return {
      showModal: false,
      assignees: Array
    }
  },
  mounted() {
    this.getAssignees()
  },
  components: {
    ChoreBoxModal
  },
  props: {
    chore: Object
  },
  methods: {
    getProgressClass(urgency) {
      const value = Math.round(urgency * 100);
      if (value <= 35) return 'is-danger';
      if (value <= 70) return 'is-warning';
      return 'is-success';
    },
    getStatusClass(status) {
      if (status <= 0.35) return 'has-text-danger';
      if (status <= 0.70) return 'has-text-warning';
      return 'has-text-success';
    },
    getAssignees() {
      axios.get('api/v1/assignees')
          .then(response => {
            this.assignees = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },
  },

}
</script>




