import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ChoreCreateForm from "@/components/ChoreCreateForm.vue";

import Chore from '../views/Chore.vue'
import Room from '../views/Room.vue'
import StatisticsView from "@/views/StatisticsView.vue"
import LeaderboardView from "@/views/LeaderboardView.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/:room_slug/:chore_slug',
    name: 'Chore',
    component: Chore
  },
  {
    path: '/room/:room_slug',
    name: 'Room',
    component: Room
  },
  {
    path: '/chores/create',
    name: 'ChoreCreateForm',
    component: ChoreCreateForm
  },
  {
    path: '/statistics',
    name: 'StatisticsView',
    component: StatisticsView
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    component: LeaderboardView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
