<template>
  <div class="page-room">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h2 class="is-size-12 has-text-centered">{{ room.name }}</h2>
      </div>

      <div
        class="column is-3"
        v-for="chore in room.chores"
        v-bind:key="chore.id"
      >

        <div class="box">
<!--          <figure class="image mb-4">-->
<!--            <img :src="chore.get_thumbnail">-->
<!--          </figure>-->

          <h3 class="is-size-4">{{ chore.name }}</h3>
          <p class="is-size-6 has-text-grey">{{ chore.score }}</p>

          <progress
              class="progress mb-4 mt-4"
              :class="getProgressClass(chore.status)"
              :value="Math.round(chore.status * 100)"
              max="100"
              style="margin-right: 10px;">{{ Math.round(chore.status * 100) }}%
          </progress>

            <div class="buttons mt-4">
              <router-link v-bind:to="chore.get_absolute_url" class="button is-dark mt-4">View Details</router-link>
<!--              <button class="button is-success">Resolve Task</button>-->
<!--              <button class="button">View Details</button>-->
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

export default {
  name: "Room",
  data() {
    return {
      room: {
        chores: []
      }
    }
  },
  mounted() {
    this.getRoom()
  },
  methods: {
    getRoom() {
      const roomSlug = this.$route.params.room_slug

      axios
          .get(`/api/v1/chores/${roomSlug}`)
          .then(response => {
            this.room = response.data

            document.title = this.room.name + '| Chores Tracker'
          })
          .catch(error => {
            console.log(error)

            toast({
              message: 'Something went wrong. Please, try again.',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right'
            })
          })
    },
    getProgressClass(urgency) {
      const value = Math.round(urgency * 100);
      if (value <= 35) return 'is-success';
      if (value <= 70) return 'is-warning';
      return 'is-danger';
    },
  }
}
</script>