<template>
  <!-- Modal -->
  <div class="modal" :class="{ 'is-active': showModal }">
    <div class="modal-background" @click="closeModal"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Submit Chore</p>
        <button class="delete" aria-label="close" @click="closeModal"></button>
      </header>
      <section class="modal-card-body">
        <p>To submit that you did this chore, select yourself from the list and click <strong>Did this Chore</strong>
          button.</p>
          <AssigneeDropdownMenu
              class="mt-4"
              :assignees="assignees"
              :chore="chore"
              v-model="selectedAssignee"
          />
      </section>
      <footer class="modal-card-foot">
        <button class="button" @click="closeModal">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import AssigneeDropdownMenu from "@/components/AssigneeDropdownMenu";
import axios from "axios";

export default {
  name: "ChoreBoxModal",
  components: {
    AssigneeDropdownMenu
  },
  props: {
    showModal: Boolean,
    chore: Object,
    assignees: Array
  },
  data() {
    return {
      selectedAssignee: "",
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
/* Optional: Add styles for your modal here */
</style>
