<template>
  <div class="page-new-chore">
    <h1 class="title">Create New Chore</h1>
    <form @submit.prevent="submitChore">
      <div class="field">
        <label class="label">Chore Name</label>
        <div class="control">
          <input class="input" type="text" v-model="chore.name" required>
        </div>
      </div>

      <div class="field">
        <label class="label">Description</label>
        <div class="control">
          <textarea class="textarea" v-model="chore.description" required></textarea>
        </div>
      </div>

      <div class="field">
        <label class="label">Room</label>
        <div class="control">
          <div class="select">
            <select v-model="chore.room_slug" required>
              <option v-for="room in rooms" :key="room.slug" :value="room.slug">{{ room.name }}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label">Score</label>
        <div class="control">
          <input class="input" type="number" v-model="chore.score" required min="1" max="10">
        </div>
      </div>

      <div class="field">
        <label class="label">Frequency</label>
        <div class="control">
          <div class="select">
            <select v-model="chore.frequency" required>
              <option :value="0">once in a week</option>
              <option :value="1">once in 2 weeks</option>
              <option :value="2">once in 3 weeks</option>
              <option :value="3">once in a month</option>
              <option :value="4">once in 2 months</option>
              <option :value="5">once in 6 months</option>
            </select>
          </div>
        </div>
      </div>

      <div class="control">
        <button class="button is-primary" type="submit">Create Chore</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'NewChore',
  data() {
    return {
      chore: {
        name: '',
        description: '',
        room_name: '',
        score: 1,
        status: 0
      },
      rooms: []
    };
  },
  created() {
    this.fetchRooms();
  },
  methods: {
    async submitChore() {
      try {
        console.log('Creating chore with room_slug:', this.chore.room_slug, this.chore.room_name);
        const response = await axios
            .post('/api/v1/chores/create/',
                {
                  room_slug: this.chore.room_slug,
                  name: this.chore.name,
                  description: this.chore.description,
                  score: this.chore.score,
                  status: this.chore.status,
                  frequency: this.chore.frequency
                });

        if (response.status === 201) {
          this.$router.push({name: 'Room', params: {room_slug: this.chore.room_slug}});
        }
      } catch (error) {
        console.error('Error creating chore:', error);
        alert('There was an error creating the chore. Please try again.');
      }
    },
    async fetchRooms() {
      try {
        const response = await axios.get('/api/v1/rooms/');
        this.rooms = response.data;
        for (let room of this.rooms) {
          console.log('Room:', room);
          room.name = room.name.replace(/-/g, ' ');
        }
      } catch (error) {
        console.error('Error fetching rooms:', error);
        alert('There was an error fetching the rooms. Please try again.');
      }
    }
  }
};
</script>

<style scoped>
.page-new-chore {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}
</style>
