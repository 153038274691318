<template>
  <div class="field has-addons">
    <div :class="{'dropdown': true, 'is-active': isDropdownActive}">
      <div class="dropdown-trigger">
        <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="toggleDropdown">
          <span>{{ dropdownButtonText }}</span>
          <span class="icon is-small"><i class="fas fa-angle-down" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="dropdown-menu" id="dropdown-menu" role="menu">
        <div class="dropdown-content">
          <a v-for="assignee in assignees" :key="assignee.id" href="#" class="dropdown-item" @click="selectOption(assignee.id, assignee.name)">
            {{ assignee.name }}
          </a>
        </div>
      </div>
    </div>
    <div class="control">
      <a class="button is-success" @click="submitChoreDone">Did this Chore</a>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AssigneeDropdownMenu",
  props: {
    assignees: Array,
    value: String,
    chore: Object
  },
  data() {
    return {
      dropdownButtonText: "Select an Assignee",
      selectedOption: -1,
      isDropdownActive: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdownActive = !this.isDropdownActive;
    },
    selectOption(assigneeId, assigneeName) {
      this.selectedOption = assigneeId;
      this.dropdownButtonText = assigneeName;
      this.isDropdownActive = false;
      this.$emit("input", this.selectedOption);
      console.log("Selected option: ", this.selectedOption);
    },
    async submitChoreDone() {
      if (this.selectedOption === -1) {
        alert("Please select one of the options")
        return
      }

      console.log("From AssigneeDropdownMenu.vue: ", this.chore.id, this.chore.slug, this.chore.room_slug, this.selectedOption);
      try {
        const response = await axios.post("/api/v1/chores/completed/", {
          chore_id: this.chore.id,
          assignee_id: this.selectedOption
        });

        if (response.status === 200) {
          this.$router.push({name: "Chore", params: {chore_slug: this.chore.slug, room_slug: this.chore.room_slug}});
        }
      } catch (error) {
        console.error("Error submitting chore:", error);
        alert("There was an error submitting the chore. Please try again.");
      }
    },
  },
  watch: {
    value(newVal) {
      this.selectedOption = newVal;
    }
  }
};
</script>
